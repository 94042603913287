import { stripRichTextWrapperTag } from '@/utils/html';

const value = (str) =>
  str
    .replace(/'/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-');

const getOptions = (primary, items) => {
  const optionsBase = {
    topicOptions: [],
    subtopicOptionsList: {},
    results: [],
  };

  return items.reduce((obj, item) => {
    const topicValue = value(item.topic);
    const subtopicValue = value(item.subtopic);

    if (!item.result_content?.uid) {
      return obj;
    }

    if (!obj.topicOptions.some((arrItem) => arrItem.label === item.topic)) {
      obj.topicOptions.push({
        value: topicValue,
        label: item.topic,
      });
    }

    if (!obj.subtopicOptionsList[topicValue]) {
      obj.subtopicOptionsList[topicValue] = [
        {
          value: '',
          label: primary.subtopic_label,
        },
      ];
    }
    obj.subtopicOptionsList[topicValue].push({
      value: subtopicValue,
      label: item.subtopic,
    });

    obj.results.push({
      topic: topicValue,
      subtopic: subtopicValue,
      uid: item.result_content.uid,
    });

    return obj;
  }, optionsBase);
};

export default ({ primary, items }) => {
  const { topicOptions, subtopicOptionsList, results } = getOptions(
    primary,
    items
  );

  return {
    title:
      primary.configurator_title &&
      stripRichTextWrapperTag(primary.configurator_title),
    selects: [
      {
        name: 'topic',
        id: 'topic',
        label: primary.topic_label,
        type: 'select',
        value: '',
        options: [
          {
            value: '',
            label: primary.topic_label,
          },
          ...topicOptions,
        ],
      },
      {
        name: 'subtopic',
        id: 'subtopic',
        label: primary.subtopic_label,
        type: 'select',
        value: '',
        canBeDisabled: true,
        options: [
          {
            value: '',
            label: primary.subtopic_label,
          },
        ],
      },
    ],
    subtopicOptionsList,
    ctaLabel: primary.cta_label,
    results,
    withoutSubmitButton: primary.without_submit_button || !primary.cta_label,
    autoSelect: primary.enable_auto_select_subtopic,
  };
};
